import request from '@/utils/request'

// 获取自助列表
export function getSelfHelpList(params) {
  return request({
    url: `/pc/documentSelf/show/v2.5.2`,
    method: 'GET',
    params
  })
}
// 获取自助详情
export function getSelfHelpDetail(id) {
  return request({
    url: `/pc/documentSelf/${id}/v2.5.2`,
    method: 'GET'
  })
}
